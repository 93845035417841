<template>
  <div>
    <div class="homeHeader" :style="showSearch?'':'box-shadow:none'">
      <div class="topNav" :style="showSearch?'margin-bottom: 32.5px':'margin-bottom: 9.5px'">
        <div>
          <span>欢迎来到慧聪行业资源网</span>
          <div v-if="user" class="userBtn">
            <div>{{ user.nickname?user.nickname:user.phone }} 欢迎使用</div>
            <div @click="logout">退出登录</div>
          </div>
          <div v-else class="loginBtn">
            <router-link to="/login">登录</router-link>
            <router-link :to="'/register?t='+Date.now()">免费注册</router-link>
          </div>
          <!-- <span>签到领积分</span> -->
        </div>
        <div class="dropdownBox">
          <!-- <a-dropdown @visibleChange="dropdownChange($event, 'collect')">
          <a :class="openDropdown === 'collect' ? 'dropdownLink selectLink' : 'dropdownLink'">
            收藏夹<a-icon :class="openDropdown === 'collect' ? 'openIcon' : ''" type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item class="dropdownItem"><a href="javascript:;">收藏的店铺</a></a-menu-item>
            <a-menu-item><a href="javascript:;">收藏的商品</a></a-menu-item>
            <a-menu-item><a href="javascript:;">收藏的商机</a></a-menu-item>
            <a-menu-item><a href="javascript:;">转发过的商品</a></a-menu-item>
            <a-menu-item><a href="javascript:;">询盘过的商品</a></a-menu-item>
            <a-menu-item><a href="javascript:;">询盘过的供应商</a></a-menu-item>
            <a-menu-item><a href="javascript:;">浏览足迹</a></a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-divider type="vertical" style="background-color: #C4C4C4" /> -->
          <a-dropdown @visibleChange="dropdownChange($event, 'shop')">
            <a  style="color:#c81528" :class="openDropdown === 'shop' ? 'dropdownLink selectLink' : 'dropdownLink'">
              易招通<a-icon :class="openDropdown === 'shop' ? 'openIcon' : ''" type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <router-link v-if="user===null" to="/login">免费开店</router-link>
                <a v-else-if="user.is_member===0" :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">免费开店</a>
                <a v-else :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">免费开店</a>
              </a-menu-item>
              <a-menu-item>
                <router-link v-if="user===null" to="/login">我的易招通</router-link>
                <a v-else-if="user.is_member===0" :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">我的易招通</a>
                <a v-else :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">我的易招通</a>
              </a-menu-item>
              <a-menu-item><router-link to="/easyMerchants">了解易招通</router-link></a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-divider type="vertical" style="background-color: #C4C4C4" />
          <a-dropdown @visibleChange="dropdownChange($event, 'purchase')">
            <a :class="openDropdown === 'purchase' ? 'dropdownLink selectLink' : 'dropdownLink'">
              采购中心<a-icon :class="openDropdown === 'purchase' ? 'openIcon' : ''" type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item @click="toPurchase">发布采购</a-menu-item>
              <a-menu-item>
                <router-link v-if="user===null" to="/login">我的采购</router-link>
                <a v-else-if="user.is_member===0" :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">我的采购</a>
                <a v-else :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=buyList'" target="_blank">我的采购</a>
              </a-menu-item>
              <a-menu-item><router-link to="/supplyGoods">找产品</router-link></a-menu-item>
              <a-menu-item><router-link to="/supplier">找厂家</router-link></a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-divider type="vertical" style="background-color: #C4C4C4" />
          <a-dropdown @visibleChange="dropdownChange($event, 'supply')">
            <a :class="openDropdown === 'supply' ? 'dropdownLink selectLink' : 'dropdownLink'">
              供应中心<a-icon :class="openDropdown === 'supply' ? 'openIcon' : ''" type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <router-link v-if="user===null" to="/login">我的报价</router-link>
                <a v-else-if="user.is_member===0" :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">我的报价</a>
                <a v-else :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=offerList'" target="_blank">我的报价</a>
              </a-menu-item>
              <a-menu-item>
                <router-link v-if="user===null" to="/login">询盘消息</router-link>
                <a v-else-if="user.is_member===0" :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">询盘消息</a>
                <a v-else :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=inquiryList'" target="_blank">询盘消息</a>
              </a-menu-item>
              <a-menu-item>
                <router-link v-if="user===null" to="/login">撮合消息</router-link>
                <a v-else-if="user.is_member===0" :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">撮合消息</a>
                <a v-else :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=memberPush'" target="_blank">撮合消息</a>
              </a-menu-item>
              <a-menu-item>
                <router-link v-if="user===null" to="/login">我的店铺</router-link>
                <a v-else-if="user.is_member===0" :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">我的店铺</a>
                <a v-else :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">我的店铺</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-divider type="vertical" style="background-color: #C4C4C4" />
          <a-dropdown @visibleChange="dropdownChange($event, 'content')">
            <a :class="openDropdown === 'content' ? 'dropdownLink selectLink' : 'dropdownLink'">
              内容中心<a-icon :class="openDropdown === 'content' ? 'openIcon' : ''" type="down" />
            </a>
            <a-menu slot="overlay">
              <!-- <a-menu-item><a href="javascript:;">我的创作中心</a></a-menu-item> -->
              <a-menu-item><router-link to="/newsList?id=642179936532469">行业动态</router-link></a-menu-item>
              <a-menu-item><router-link to="/newsList?id=654588749425914">行业报告</router-link></a-menu-item>
              <!-- <a-menu-item><a href="javascript:;">行业专家</a></a-menu-item> -->
            </a-menu>
          </a-dropdown>
          <a-divider type="vertical" style="background-color: #C4C4C4" />
          <!-- <a-dropdown @visibleChange="dropdownChange($event, 'channel')">
          <a :class="openDropdown === 'channel' ? 'dropdownLink selectLink' : 'dropdownLink'">
            渠道服务
          </a>
        </a-dropdown>
        <a-divider type="vertical" style="background-color: #C4C4C4" /> -->
          <a-dropdown @visibleChange="dropdownChange($event, 'service')">
            <a :class="openDropdown === 'service' ? 'dropdownLink selectLink' : 'dropdownLink'">
              服务中心<a-icon :class="openDropdown === 'service' ? 'openIcon' : ''" type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item><router-link to="/service">客服中心</router-link></a-menu-item>
              <!-- <a-menu-item><a href="javascript:;">帮助中心</a></a-menu-item> -->
              <a-menu-item><router-link to="/about">关于我们</router-link></a-menu-item>
              <a-menu-item><a href="https://hczyw.com/guide/user" target="_blank">新手指引</a></a-menu-item>
              <!-- <a-menu-item><a href="javascript:;">合作案例</a></a-menu-item> -->
            </a-menu>
          </a-dropdown>
          <a-divider type="vertical" style="background-color: #C4C4C4" />
          <a-dropdown @visibleChange="dropdownChange($event, 'industry')">
            <a :class="openDropdown === 'industry' ? 'dropdownLink selectLink' : 'dropdownLink'">
              行业分类<a-icon :class="openDropdown === 'industry' ? 'openIcon' : ''" type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item><a href="https://www.hvacr.hczyw.com/" target="_blank">暖通与舒适家居</a></a-menu-item>
              <a-menu-item><a href="https://www.cm.hczyw.com/" target="_blank">工程机械</a></a-menu-item>
              <a-menu-item><a href="https://www.secu.hczyw.com/" target="_blank">安防行业</a></a-menu-item>
              <a-menu-item><a href="https://www.fire.hczyw.com/" target="_blank">消防行业</a></a-menu-item>
              <a-menu-item><a href="https://www.hotel.hczyw.com/" target="_blank">酒店行业</a></a-menu-item>
              <a-menu-item><a href="https://www.qipei.hczyw.com/" target="_blank">汽车行业</a></a-menu-item>
              <a-menu-item><a href="https://www.dj.hczyw.com/" target="_blank">商业展示</a></a-menu-item>
              <a-menu-item><a href="https://www.ledp.hczyw.com/" target="_blank">LED行业</a></a-menu-item>
              <a-menu-item><a href="https://www.audio.hczyw.com/" target="_blank">专业音响灯光</a></a-menu-item>
              <a-menu-item><a href="https://www.edu.hczyw.com/" target="_blank">广电教育</a></a-menu-item>
              <a-menu-item><a href="https://www.water.hczyw.com/" target="_blank">水工业行业</a></a-menu-item>
              <a-menu-item><a href="http://www.im.hczyw.com/" target="_blank">智造网</a></a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
      <div v-if="showSearch">
        <div :class="scrollPosition>46.5?'searchBox fixedSearch':'searchBox'">
          <div>
            <div @click="toDetail('home')"><img src="@/assets/logo.png"></div>
            <div>
              <a-input-search v-model="searchText" class="searchInput" allowClear :placeholder="searchPlaceHolder" enter-button="搜索" size="large" @search="onSearch">
                <div slot="prefix" class="searchSelector">
                  <a-select default-value="采购" style="width: 120px" v-model="searchType" @change="searchTypeChange">
                    <a-icon slot="suffixIcon" type="caret-down" theme="filled" style="font-size:15px;color:#666" />
                    <a-select-option value="采购">采购</a-select-option>
                    <a-select-option value="供应商">供应商</a-select-option>
                    <a-select-option value="商品">商品</a-select-option>
                    <a-select-option value="发现">资讯</a-select-option>
                    <!-- <a-select-option value="推荐">推荐</a-select-option> -->
                  </a-select>
                  <a-divider type="vertical" style="background-color: #C4C4C4;width:2px;height:24px;margin:0 13px 0 5px" />
                  <span class="iconfont icon-search" style="font-size:20px"></span>
                </div>
              </a-input-search>
            </div>
            <div class="searchBtn">
              <!-- <div @click="toDetail('freeSample')"> -->
              <div @click="openModal('免费拿样')">
                <span class="iconfont icon-btm_nayang"></span>免费拿样
              </div>
              <div @click="openModal('快速询价')">
                <span class="iconfont icon-btm_price"></span>快速询价
              </div>
            </div>
          </div>
        </div>
        <div class="menuBox">
          <a-dropdown v-if="showMenu" class="allIndustry" @visibleChange="menuChange($event, 'all')">
            <a :class="openMenu === 'all' ? 'selectMenu' : ''">
              <span class="iconfont icon-classify_all"></span>全部分类
            </a>
          </a-dropdown>
          <div v-else style="margin-right: 100px;width:102px;height:60px"></div>
          <a-dropdown class="bottomNav" @visibleChange="menuChange($event, 'supply')">
            <a :class="openMenu === 'supply' ? 'selectMenu' : ''">
              供应信息<span :class="openMenu === 'supply' ? 'iconfont icon-down selectIcon' : 'iconfont icon-down'"></span>
            </a>
          </a-dropdown>
          <a-dropdown class="bottomNav" @visibleChange="menuChange($event, 'purchase')">
            <router-link to="/purchaseList" :class="openMenu === 'purchase' ? 'selectMenu' : ''">
              采购信息
            </router-link>
          </a-dropdown>
          <a-dropdown class="bottomNav" @visibleChange="menuChange($event, 'discover')">
            <a :class="openMenu === 'discover' ? 'selectMenu' : ''">
              资讯<span :class="openMenu === 'discover' ? 'iconfont icon-down selectIcon' : 'iconfont icon-down'"></span>
            </a>
          </a-dropdown>
          <!-- <a-dropdown class="bottomNav" @visibleChange="menuChange($event, 'recommend')">
          <a :class="openMenu === 'recommend' ? 'selectMenu' : ''">
            推荐<span :class="openMenu === 'recommend' ? 'iconfont icon-down selectIcon' : 'iconfont icon-down'"></span>
          </a>
        </a-dropdown> -->
          <a-dropdown class="bottomNav" @visibleChange="menuChange($event, 'service')">
            <a :class="openMenu === 'service' ? 'selectMenu' : ''">
              营销服务<span :class="openMenu === 'service' ? 'iconfont icon-down selectIcon' : 'iconfont icon-down'"></span>
            </a>
          </a-dropdown>
          <a-dropdown class="bottomNav" @visibleChange="menuChange($event, 'cross')">
            <a href="https://thm.hczyw.com" target="_blank" :class="openMenu === 'cross' ? 'selectMenu' : ''">
              跨境服务
            </a>
          </a-dropdown>
        </div>
      </div>
      <div :class="openMenu === 'all' ? 'allMenu selectAll' : 'allMenu'">
        <div class="allBox" @mouseleave="childrenOut">
          <div class="allItem" @mouseenter="handleMouseOver" @mouseleave="handleMouseOut">
            <div v-for="(item,i) in industrys" :key="i" @mouseenter="industryHover(i)" @mouseleave="industryOut" @click="$router.push('/supplyGoods?columns='+item.id+'&parent='+item.id)"><svg class="icon" aria-hidden="true">
                <use :xlink:href="'#icon-'+item.icon"></use>
              </svg>{{item.title}}</div>
          </div>
          <div :class="showChildren?'childrenBox showChildren':'childrenBox'" @mouseenter="childrenHover">
            <div class="childrenTitle">{{ hoverIndex==null?'':industrys[hoverIndex].title }}</div>
            <a-spin :spinning="industryLoading" style="height:calc(100% - 124px)">
              <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
              <div v-if="hoverIndex!=null" class="childContent">
                <div v-for="(item,i) in industrys[hoverIndex].children" :key="i">
                  <router-link @click.native="childrenOut" :to="'/supplyGoods?columns='+item.id">{{ item.title }} <span class="iconfont icon-right"></span></router-link>
                  <div class="childList">
                    <router-link @click.native="childrenOut" :to="'/supplyGoods?columns='+child.id" v-for="(child,j) in item.children" :key="j">
                      {{child.title}}
                    </router-link>
                  </div>
                </div>
              </div>
            </a-spin>
          </div>
        </div>
      </div>
      <div :class="openMenu === 'supply' ? 'menuItem selectItem' : 'menuItem'" @mouseenter="handleMouseOver" @mouseleave="handleMouseOut">
        <div class="itemBox">
          <div><router-link to="/supplier">供应商</router-link></div>
          <div><router-link to="/supplyGoods">供应产品</router-link></div>
        </div>
      </div>
      <div :class="openMenu === 'discover' ? 'menuItem selectItem' : 'menuItem'" @mouseenter="handleMouseOver" @mouseleave="handleMouseOut">
        <div class="itemBox">
          <div v-for="(item,i) in columns[645398072031211].children" :key="i"><router-link :to="'/newsList?id='+item.id">{{item.title?item.title:''}}</router-link></div>
          <!-- <div><router-link to="/newsList">行业报告</router-link></div>
          <div><router-link to="/newsList">展会资讯</router-link></div> -->
        </div>
      </div>
      <div :class="openMenu === 'recommend' ? 'menuItem selectItem' : 'menuItem'" @mouseenter="handleMouseOver" @mouseleave="handleMouseOut">
        <div class="itemBox">
          <div v-for="(item,i) in columns[645398114839617].children" :key="i"><router-link :to="'/recommendList?id='+item.id">{{item.title?item.title:''}}</router-link></div>
          <!-- <div v-for="(item,i) in columns[645398114839617].children" :key="i"><router-link to="">{{item.title}}</router-link></div> -->
          <!-- <div>名人采访</div>
          <div>企业宣传</div>
          <div>产品测评</div>
          <div>行业展会</div>
          <div>盛会回放</div>
          <div>直播回放</div> -->
        </div>
      </div>
      <div :class="openMenu === 'service' ? 'menuItem selectItem' : 'menuItem'" @mouseenter="handleMouseOver" @mouseleave="handleMouseOut">
        <div class="itemBox">
          <div><router-link to="/tiktok">抖音竞价</router-link></div>
          <div><router-link to="/superConditions">超级商情</router-link></div>
          <div><router-link to="/easyMerchants">易招通</router-link></div>
          <div><router-link to="/shopAbout">云商铺</router-link></div>
          <!-- <div>巡展活动</div>
          <div>慧聪探厂</div> -->
        </div>
      </div>
      <a-modal class="modal bigModal" :footer="null" closable v-if="modalVisible" v-model="modalVisible" :title="modalName" centered width="900px">
        <a-form :form="form" labelAlign="left" @submit="formSubmit">
          <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="企业名称">
            <a-input v-decorator="[
            'enterprise_title',
            { rules: [{ required: true, message: '请输入企业名称' }] },
          ]" placeholder="请输入企业名称" />
          </a-form-item>
          <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="所属行业">
            <a-input v-decorator="[
            'industry',
            { rules: [{ required: true, message: '请输入所属行业' }] },
          ]" placeholder="请输入所属行业" />
          </a-form-item>
          <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="经营商品">
            <a-input v-decorator="[
            'product',
            { rules: [{ required: true, message: '请输入经营商品' }] },
          ]" placeholder="请输入经营商品" />
          </a-form-item>
          <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="手机号">
            <a-input v-decorator="[
            'phone',
            { rules: [{ required: true, message: '请输入手机号' }] },
          ]" :auto-size="{ minRows: 6, maxRows: 10 }" placeholder="请输入手机号" />
          </a-form-item>
          <a-form-item style="text-align: center;margin-bottom:0">
            <a-button :loading="loading" html-type="submit" class="submitBtn" type="primary">
              提交资料
            </a-button>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    <div class="mobileHomeHeader">
      <div>
        <span class="iconfont icon-classify_all" @click="showMobileMenu=true"></span>
        <div class="logoBox" @click="toDetail('home')">
          <img src="@/assets/logo.png">
        </div>
        <div class="searchBox">
          <a-popover v-model="showMobSearch" trigger="click" placement="bottomRight">
            <span class="iconfont icon-search"></span>
            <template slot="content">
              <div class="searchTypes">
                <div @click="toMobileNav('/purchaseList')">采购</div>
                <div @click="toMobileNav('/supplier')">供应商</div>
                <div @click="toMobileNav('/supplyGoods')">商品</div>
                <div @click="toMobileNav('/newsList')">资讯</div>
              </div>
            </template>
          </a-popover>
          <a-popover trigger="click" placement="bottomRight">
            <span class="iconfont icon-user"></span>
            <template slot="content">
              <div class="searchTypes">
                <template v-if="!this.user">
                  <router-link to="/login">登录</router-link>
                  <router-link :to="'/register?t='+Date.now()">注册</router-link>
                </template>
                <template v-else>
                  <a style="color:#333" :href="memberURL+'/member?id='+user.id+'&token='+token+'&page=home'" target="_blank">个人中心</a>
                  <div @click="logout">退出登录</div>
                </template>
              </div>
            </template>
          </a-popover>
        </div>
      </div>
      <div class="searchInputBox">
        <a-input-group compact>
          <a-select style="width: 96px;height: 36px;" default-value="采购" v-model="searchType" @change="searchTypeChange">
            <a-icon slot="suffixIcon" type="caret-down" style="color:#666;font-size:14px" />
            <a-select-option value="采购">采购</a-select-option>
            <a-select-option value="供应商">供应商</a-select-option>
            <a-select-option value="商品">商品</a-select-option>
            <a-select-option value="发现">资讯</a-select-option>
          </a-select>
          <a-input style="width: calc(100% - 96px);height: 36px;padding-right: 60px;" :placeholder="searchPlaceHolder" v-model="searchText"></a-input>
          <a-button type="primary" @click="onSearch(searchText)">搜索</a-button>
        </a-input-group>
      </div>
    </div>
    <a-drawer placement="left" :visible="showMobileMenu" :closable="false" :maskClosable="true" @close="showMobileMenu=false" width="200" class="mobilMenuBox">
      <a-menu mode="inline" width="200">
        <a-menu-item key="-1" @click="toDetail('home')">
          首页
        </a-menu-item>
        <a-sub-menu>
          <span slot="title" class="submenu-title-wrapper">供应信息</span>
          <a-menu-item key="0" @click="toDetail('supplier')">
            供应商
          </a-menu-item>
          <a-menu-item key="1" @click="toDetail('supplyGoods')">
            供应产品
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="2" @click="toDetail('purchaseList')">
          采购信息
        </a-menu-item>
        <a-sub-menu>
          <span slot="title" class="submenu-title-wrapper">资讯</span>
          <a-menu-item v-for="(item,i) in columns[645398072031211].children" :key="i" @click="toDetail('newsList',item.id)">
            {{item.title?item.title:''}}
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu>
          <span slot="title" class="submenu-title-wrapper">营销服务</span>
          <a-menu-item key="3" @click="toDetail('tiktok')">
            抖音竞价
          </a-menu-item>
          <a-menu-item key="4" @click="toDetail('superConditions')">
            超级商情
          </a-menu-item>
          <a-menu-item key="5" @click="toDetail('easyMerchants')">
            易招通
          </a-menu-item>
          <a-menu-item key="6" @click="toDetail('shopAbout')">
            云商铺
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="7" @click="toDetail('thm')">
          跨境服务
        </a-menu-item>
      </a-menu>
      <!-- <div class="mobileMenuItem">
        <div v-for="(item,i) in industrys" :key="i" style="height:36px;display:flex;align-items:center;" @click="toMobileGoods(item.id)">
          <svg class="icon" aria-hidden="true" style="width:16px;height:16px;margin-right:9px">
            <use :xlink:href="'#icon-'+item.icon"></use>
          </svg>{{item.title}}
        </div>
      </div> -->
    </a-drawer>
  </div>
</template>

<script>
import { faxianColumns, liuYan, industry } from "@/api/home";
import { logout, hqEquityCG } from "@/api/user";
import { getToken, getUserInfo, removeToken, removeUserInfo } from "@/utils/auth";
import { mapState } from "vuex";
export default {
  name: "Header",
  props: {
    showMenu: Boolean,
    showSearch: Boolean,
  },
  computed: {
    ...mapState({
      counter: (state) => state.isLogin,
    }),
  },
  watch: {
    $route() {
      this.getUser();
    },
    "$route.query.text": {
      handler(newVal) {
        this.searchText = newVal;
      },
    },
    counter(newValue) {
      if (newValue) {
        this.getUser();
      }
    },
  },
  data: function () {
    return {
      packageLoading: false,
      homeURL: process.env.VUE_APP_SUBDOMAINURL,
      memberURL: process.env.VUE_APP_MEMBERURL,
      showMobSearch: false,
      showMobileMenu: false,
      searchText: "",
      modalName: "",
      searchPlaceHolder: "请输入采购关键词",
      showChildren: false,
      industryLoading: false,
      hoverIndex: null,
      loading: false,
      modalVisible: false,
      user: null,
      openDropdown: null,
      openMenu: null,
      searchType: "采购",
      columns: {
        642215010970155: {
          children: [],
        },
        645398072031211: {
          children: [],
        },
        645398114839617: {
          children: [],
        },
      },
      form: this.$form.createForm(this, { name: "freeSample" }),
      formItemLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
      },
      formTailLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21, offset: 4 },
      },
      industrys: [],
      token: "",
      scrollPosition: 0,
    };
  },
  methods: {
    toMobileNav(path) {
      this.showMobSearch = false;
      this.$router.push({
        path,
        query: { t: Date.now() },
      });
    },
    toMobileGoods(columns) {
      this.$router.push({
        path: "/supplier",
        query: {
          columns,
          t: Date.now(),
        },
      });
      this.showMobileMenu = false;
    },
    industryHover(index) {
      if (this.industryLoading) {
        return;
      }
      this.hoverIndex = index;
      this.showChildren = true;
      if (this.industrys[index].children.length === 0) {
        this.getChildren();
      }
    },
    industryOut() {
      if (this.industryLoading) {
        return;
      }
      this.showChildren = false;
    },
    childrenHover() {
      this.showItem = true;
      this.openMenu = "all";
      this.showChildren = true;
    },
    childrenOut() {
      this.showItem = false;
      this.openMenu = null;
      this.showChildren = false;
    },
    getChildren() {
      if (this.industryLoading) {
        return;
      }
      this.industryLoading = true;
      let data = {
        parent_id: this.industrys[this.hoverIndex].id,
      };
      industry(data).then((res) => {
        this.industrys[this.hoverIndex].children = res.data.data;
        this.industryLoading = false;
      });
    },
    getIndustry() {
      let data = {
        limit: 13,
        parent_id: 0,
      };
      industry(data).then((res) => {
        this.industrys = res.data.data;
        for (let i in this.industrys) {
          this.getSecond(i);
        }
      });
    },
    getSecond(i) {
      let data = {
        parent_id: this.industrys[i].id,
      };
      industry(data).then((res) => {
        this.industrys[i].children = res.data.data;
      });
    },
    getPackage() {
      if(this.packageLoading){
        return;
      }
      this.packageLoading = true;
      hqEquityCG().then(res => {
        if(res.success){
          if(res.obj['采购信息发布'].type){
            this.$router.push('/publishPurchase');
          }else{
            this.$message.error('您发布的采购信息已达上限');
          }
        }else{
          this.$message.error(res.message);
        }
        this.packageLoading = false;
      })
    },
    toPurchase() {
      if (this.user === null) {
        this.$router.push("/login") ;
      } else {
        this.getPackage();
      }
    },
    formSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let data = values;
          data.source = "留资";
          this.loading = true;
          liuYan(data).then((res) => {
            if (res.data.result) {
              this.$message.success(res.message);
              this.modalVisible = false;
            } else {
              this.$message.error(res.message);
            }
            this.loading = false;
          });
        }
      });
    },
    openModal(e) { 
      this.modalVisible = true;
      this.modalName = e;
    },
    getColumns() {
      faxianColumns().then((res) => {
        this.columns = res;
      });
    },
    getUser() {
      this.user = getUserInfo();
      this.token = getToken();
    },
    toDetail(name, id) {
      if (name == "newsList") {
        this.$router.push({
          name: "newsList",
          query: { t: Date.now(), id },
        });
      } else if (name == "thm") {
        window.open("https://thm.hczyw.com", "_blank");
      } else {
        this.$router.push({
          name,
          query: { t: Date.now() },
        });
      }
      this.showMobileMenu = false;
    },
    dropdownChange(e, name) {
      if (e) {
        this.openDropdown = name;
      } else {
        this.openDropdown = null;
      }
    },
    menuChange(e, name) {
      if (e) {
        this.openMenu = name;
      } else {
        setTimeout(() => {
          if (!this.showItem) {
            this.openMenu = null;
          }
        }, 50);
      }
    },
    handleMouseOver() {
      this.showItem = true;
    },
    handleMouseOut() {
      this.showItem = false;
      this.openMenu = null;
    },
    searchTypeChange(e) {
      this.searchType = e;
      this.searchPlaceHolder = `请输入${e=='发现'?'资讯':e}关键词`;
    },
    onSearch(e) {
      switch (this.searchType) {
        case "发现":
          this.$router.push({
            name: "newsSearch",
            query: { text: e, t: Date.now() },
          });
          break;
        case "商品":
          this.$router.push({
            name: "goodsSearch",
            query: { text: e, t: Date.now() },
          });
          break;
        case "供应商":
          this.$router.push({
            name: "brandSearch",
            query: { text: e, t: Date.now() },
          });
          break;
        case "采购":
          this.$router.push({
            name: "purchaseSearch",
            query: { text: e, t: Date.now() },
          });
          break;
        case "推荐":
          this.$router.push({
            name: "recommendSearch",
            query: { text: e, t: Date.now() },
          });
          break;
      }
    },
    logout() {
      logout().then(res=>{
        if(res.data.result){
          this.user = null;
          removeUserInfo();
          removeToken();
          this.$store.commit("logout");
          this.$message.success("已退出登录");
          setTimeout(()=>{
            window.location.replace(this.homeURL);
          },500)
        }else{
          this.$message.error(res.message);
        }
      })
    },
    handleScroll() {
      this.scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.getUser();
    this.getColumns();
    this.getIndustry();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less">
@media screen and (min-width: 1025px) {
  .mobileHomeHeader {
    display: none;
  }
  .homeHeader {
    box-shadow: 0px 1px 6px 0px rgba(153, 153, 153, 0.4);
    padding: 9.5px 24px 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    min-width: 1248px;
    margin: 0;

    .topNav {
      font-size: 12px;
      line-height: 14px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      letter-spacing: 0px;
      font-weight: normal;
      margin-bottom: 32.5px;

      > div:first-child {
        display: flex;
        align-items: center;
        span {
          margin-right: 18px;
        }
        > span:first-child {
          margin-right: 36px;
        }
        .loginBtn {
          > a {
            margin-right: 18px;
            color: var(--default);
            cursor: pointer;
          }
        }
        .userBtn {
          display: flex;
          > div {
            margin-right: 18px;
          }
          > div:last-child {
            color: var(--default);
            cursor: pointer;
          }
        }
        > span:last-child {
          color: #f39800;
          cursor: pointer;
        }
      }

      .dropdownBox {
        display: flex;
        align-items: center;

        .dropdownLink {
          color: #333;
          display: flex;
          margin: 0 10px;
          align-items: center;
          transition: all 0.5s;

          i {
            margin-left: 4px;
            transition: all 0.3s;
          }
        }
        .dropdownLink:hover {
          color: var(--default);
        }
        .selectLink {
          // color: var(--default);

          .openIcon {
            transform: rotate(180deg);
          }
        }
      }
    }

    .searchBox {
      background-color: #fff;
      > div {
        display: inline-flex;
        justify-content: space-between;
        width: 1200px;
        margin-bottom: 20px;
        > div:first-child {
          cursor: pointer;
        }
        img {
          width: 200px;
          height: 40px;
        }

        .searchInput {
          width: 640px;
          height: 40px;
        }

        .searchInput .ant-input {
          padding-left: 150px;
          border: 2px solid var(--default) !important;
        }

        .ant-input-prefix {
          left: 2px;
        }

        .searchSelector {
          color: #333;
          display: flex;
          align-items: center;

          .ant-select {
            width: 100px !important;
          }

          .ant-select-selection {
            height: 36px;
            border: none;
            display: flex;
            align-items: center;
          }

          .ant-select-focused {
            border-color: transparent;
            box-shadow: none;
          }

          .ant-select-selection {
            border: none !important;
            box-shadow: none;
          }

          .ant-select-dropdown-menu-item:hover:not(
              .ant-select-dropdown-menu-item-disabled
            ) {
            background-color: rgba(200, 21, 40, 0.2) !important;
          }
        }

        .searchBtn {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;

          > div {
            box-sizing: border-box;
            border: 1px solid #c81528;
            width: 126px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            font-size: 16px;
            color: #c81528;
            cursor: pointer;

            span {
              margin-right: 7px;
            }
          }

          > div:first-child {
            margin-right: 20px;
          }
        }
      }
    }
    .fixedSearch{
      transition: all 0.2s linear;
      position:fixed;
      top:0;
      left:0;
      padding-top:20px;
      width:100%;
      text-align:center;
      background-color: #fff;
      box-shadow: 0px 1px 6px 0px rgba(153, 153, 153, 0.4);
      z-index: 14;
    }

    .menuBox {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      width: 1200px;
      height: 60px;
      padding-bottom: 9.5px;

      .allIndustry {
        color: #333;
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;
        height: 60px;
        line-height: 60px;
        display: flex;
        align-items: center;
        margin-right: 100px;

        span {
          width: 20px;
          font-size: 20px;
          margin-right: 10px;
        }
      }

      .bottomNav {
        color: #333;
        font-size: 18px;
        margin-right: 64px;
        height: 60px;
        line-height: 60px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        span {
          font-size: 24px;
          color: #4e5969;
          margin-left: 5px;
          transition: all 0.3s;
        }

        .selectIcon {
          color: var(--default);
          transform: rotate(180deg);
        }
      }

      .selectMenu {
        color: var(--default);
      }
    }

    .menuItem {
      width: 100%;
      height: 0;
      position: absolute;
      bottom: 0;
      background-color: var(--white);
      transition: all 0.3s;
      border-top: 1px solid #eaeaea;
      box-sizing: border-box;
      display: flex;
      opacity: 0;
      justify-content: center;
      overflow: hidden;
      z-index: 11;

      .itemBox {
        width: 1200px;
        display: flex;
        align-items: center;
        padding-left: 190px;

        > div {
          width: 84px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          margin-right: 64px;
          cursor: pointer;
          border-radius: 4px;
          transition: all 0.3s;
          a {
            width: 84px;
            height: 30px;
            display: inline-block;
            color: #333;
          }
        }
        > div:hover {
          color: var(--default);
          background-color: rgba(200, 21, 40, 0.2);
          a {
            color: var(--default);
          }
        }
      }
    }
    .selectItem {
      opacity: 1;
      height: 64px;
      bottom: -64px;
      box-shadow: 0px 5px 10px -5px rgba(153, 153, 153, 0.4);
    }
    .allMenu {
      width: 100%;
      height: 0;
      position: absolute;
      bottom: 0;
      background-color: var(--white);
      transition: all 0.3s;
      display: flex;
      opacity: 0;
      justify-content: center;
      overflow: hidden;
      background-color: transparent;
      z-index: 11;
      .allBox {
        text-align: left;
        width: 1200px;
        display: flex;
        .allItem {
          width: 180px;
          > div {
            background-color: var(--white);
            width: 180px;
            height: 36px;
            display: flex;
            align-items: center;
            transition: all 0.3s;
            cursor: pointer;
            color: #333;
            font-size: 14px;
            letter-spacing: 0px;
            padding-left: 20px;
            position: relative;
            span,
            svg {
              font-size: 16px;
              margin-right: 9px;
            }
            svg:last-of-type {
              transition: all 0.3s;
              fill: #333333;
            }
          }
          > div::after {
            content: "";
            position: absolute;
            right: -26px;
            width: 0;
            height: 0;
            opacity: 0;
            border-top: 16px solid transparent;
            border-right: 16px solid transparent;
            border-left: 16px solid #f4f4f4;
            border-bottom: 16px solid transparent;
            transition: 0.3s all linear;
          }
          > div:hover {
            color: var(--default);
            background-color: #f4f4f4;
            &::after {
              opacity: 1;
            }
            svg:last-of-type {
              fill: var(--default) !important;
            }
          }
        }
        .childrenBox {
          width: 0;
          padding: 20px;
          height: 468px;
          background-color: var(--white);
          opacity: 0;
          transition: all 0.3s;
          .childrenTitle {
            font-size: 16px;
            font-weight: 600;
            color: var(--default);
            margin: 10px 0;
          }
          .childContent {
            height: 384px;
            overflow-y: auto;
            > div {
              display: flex;
              font-size: 12px;
              padding: 14px 0 0 0;
              border-bottom: 1px dashed #e1e1e1;
              > a:first-child {
                width: 87px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 14px;
                color: #333;
              }
              .childList {
                width: calc(100% - 87px);
                padding-right: 10px;
                display: flex;
                flex-wrap: wrap;
                > a {
                  color: #333;
                  height: 15px;
                  margin-bottom: 14px;
                  padding: 0 8px;
                  cursor: pointer;
                }
                > a:not(:last-child) {
                  border-right: 1px solid #ccc;
                }
                > a:hover {
                  color: var(--default);
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .showChildren {
          width: calc(100% - 180px);
          opacity: 1;
        }
      }
    }
    .selectAll {
      opacity: 1;
      height: 524px;
      bottom: -524px;
    }
    .icon {
      width: 1em;
      height: 1em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }

  .ant-dropdown {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item:hover {
        background-color: rgba(200, 21, 40, 0.2) !important;
      }
    }
  }

  .ant-select-dropdown-menu {
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
  }

  .ant-select-dropdown-menu-item-selected {
    color: #c81528;
    font-weight: 600;
  }

  .ant-select-dropdown-menu-item:hover:not(
      .ant-select-dropdown-menu-item-disabled
    ) {
    color: #c81528;
    background-color: rgba(200, 21, 40, 0.2) !important;
  }
}
@media screen and (max-width: 1024px) {
  .noHeader {
    display: none;
  }
  .homeHeader {
    display: none;
  }
  .mobileHomeHeader {
    box-shadow: 0px 2px 4px 0px rgba(151, 156, 183, 0.3);
    > div:first-child {
      width: 100%;
      height: 42px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .iconfont {
        font-size: 20px;
      }
      > .logoBox {
        position: absolute;
        top: 7px;
        left: calc(50% - 77px);
        img {
          width: auto;
          height: 28px;
        }
      }
      .searchBox {
        span {
          font-size: 20px;
          margin-left: 20px;
          color: #666;
        }
      }
    }
    .searchInputBox {
      width: 100%;
      height: 60px;
      padding: 12px;
      .ant-input-group {
        position: relative;
        .ant-btn {
          position: absolute;
          right: 3px;
          top: calc(50% - 15px);
          padding: 0;
          width: 50px;
          height: 30px;
          font-size: 14px;
          font-weight: 400;
          border-radius: 100px;
          z-index: 10;
        }
        .ant-select {
          position: relative;
        }
        .ant-select::before {
          content: "";
          position: absolute;
          right: 0;
          top: calc(50% - 12px);
          width: 1px;
          height: 24px;
          background: #e1e1e1;
          border-radius: 7px;
          z-index: 10;
        }
        .ant-select-selection {
          height: 36px;
          border-top-left-radius: 646px !important;
          border-bottom-left-radius: 646px !important;
          border-color: #c81528;
          border-right: none;
        }
        .ant-select-selection__rendered,
        .ant-select-selection-selected-value {
          color: #666;
          height: 36px;
          line-height: 36px;
        }
        .ant-input {
          border-top-right-radius: 646px !important;
          border-bottom-right-radius: 646px !important;
          border-color: #c81528;
          border-left: none;
          text-align: left;
        }
        .ant-input:focus,
        .ant-select-open .ant-select-selection,
        .ant-select-selection:active,
        .ant-select-focused .ant-select-selection {
          box-shadow: none !important;
        }
      }
    }
  }
  .ant-popover-inner-content {
    padding: 0 !important;
  }
  .searchTypes {
    > a,
    > div {
      color: #333;
      display: block;
      padding: 8px 10px;
      text-align: center;
    }
  }
  .MobileMenu {
    > div {
      svg {
        font-size: 16px;
      }
    }
  }
  .mobilMenuBox {
    .ant-drawer-body {
      padding: 0;
      .ant-menu-item,
      .ant-menu-submenu-title {
        font-size: 16px;
        font-weight: 500;
      }
      .ant-menu-sub {
        .ant-menu-item,
        .ant-menu-submenu-title {
          font-size: 14px;
          color: #999 !important;
        }
      }
      .ant-menu-submenu-active,
      .ant-menu-submenu-selected {
        color: #333;
      }
      .ant-menu-submenu-title:hover,
      .ant-menu-item:hover,
      .ant-menu-item-active,
      .ant-menu-item-selected {
        color: #333;
      }
      .ant-menu-submenu-title:active,
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #fff;
      }
      .ant-menu-submenu-inline
        > .ant-menu-submenu-title:hover
        .ant-menu-submenu-arrow::after,
      .ant-menu-submenu-inline
        > .ant-menu-submenu-title:hover
        .ant-menu-submenu-arrow::before {
        background: #333;
      }
      .ant-menu-inline .ant-menu-item::after {
        border-right: 3px solid #fff;
      }
    }
  }
}
</style>