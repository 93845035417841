import request from '@/utils/request'

// 轮播图
export function homeBanners(parameter) {
  return request({
    url: 'app/web/home/banners',
    method: 'post',
    data: parameter
  })
}

// 最新公告
export function newNotice(parameter) {
  return request({
    url: 'app/web/home/newNotice',
    method: 'post',
    data: parameter
  })
}

// 最新采购
export function newBuy(parameter) {
  return request({
    url: 'app/web/home/newBuy',
    method: 'post',
    data: parameter
  })
}

// 精选供应商
export function recommendShop(parameter) {
  return request({
    url: 'app/web/home/recommendShop',
    method: 'post',
    data: parameter
  })
}

// 优选商品
export function youXuanProduct(parameter) {
  return request({
    url: 'app/web/home/youXuanProduct',
    method: 'post',
    data: parameter
  })
}

// 行业分类
export function industry(parameter) {
  return request({
    url: 'app/web/home/industry',
    method: 'post',
    data: parameter
  })
}

// 发现栏目
export function faxianColumns(parameter) {
  return request({
    url: 'app/web/home/columns',
    method: 'post',
    data: parameter
  })
}

// 发现
export function faxian(parameter) {
  return request({
    url: '/app/web/home/faxian',
    method: 'post',
    data: parameter
  })
}

// 推荐
export function tuijian(parameter) {
  return request({
    url: 'app/web/home/tuijian',
    method: 'post',
    data: parameter
  })
}

// 所有地市
export function provinceCity(parameter) {
  return request({
    url: 'app/provinceCitySelector',
    method: 'get',
    data: parameter
  })
}

// 通用留言
export function liuYan(parameter) {
  return request({
    url: 'app/web/submitForm/liuYan',
    method: 'post',
    data: parameter
  })
}

// 优质供应商
export function goodList(parameter) {
  return request({
    url: 'app/web/home/yzgys',
    method: 'post',
    data: parameter
  })
}

// 采购收藏
export function buyCollect(parameter) {
  return request({
    url: 'app/web/buy/buyCollect',
    method: 'post',
    data: parameter
  })
}

// 采购取消收藏
export function buyCancelCollect(parameter) {
  return request({
    url: 'app/web/buy/buyCancelCollect',
    method: 'post',
    data: parameter
  })
}

// 首页广告
export function ADList(parameter) {
  return request({
    url: 'app/advertisement/limitList',
    method: 'post',
    data: parameter
  })
}
